import { useUserData } from "context/environmentContext/EnvironmentContext";
import {
  usePropertyAllowedActions,
  usePropertyDetails,
  usePropertyGuidelines,
  usePropertyLicenceActions,
  usePropertyUpdate,
  usePropertySponsorContext,
} from "context/propertyContext/PropertyContext";
import usePickSponsor from "Hooks/usePickSponsor";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { Typography } from "v2/components";
import Markdown from "v2/components/Markdown/Markdown";
import PlanningView from "v2/components/PlanningView/PlanningView";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import { documentTypes, planningText } from "./constants";
import styles from "./Planning.module.css";
import { ReactComponent as Upgrade } from "images/upgrade.svg";
import { ReactComponent as SponsorIcon } from "images/sponsor.svg";
import LicencesList from "v2/containers/Licences";

const buildingOnlyMarkdown = {
  England: `**England**

We have checked Article 4 HMO and Sui Generis Planning permission for HMOs it is not required. 
<br/>
There are many types of planning permission and we have only checked the ones pertaining to HMOs, other examples could be, but not limited to, if you have made any modifications to the property or the property is a Listed Building or is in a Conservation Areas. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors, most notably how long it has been operated in its current form and whether you can prove it.
<br/>
If you believe the property to be an 254 HMO, please run the report again with 'Property Type' set to 'House'. 
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>`,
  Wales: `**Wales**

We have checked HMO Planning permission and if your property is an HMO you will require Planning permission. 
<br/>

There are many types of planning permission and we have only check the ones pertaining to HMOs, other examples could be, but not limited to, if you have made any modifications to the property pr the property is a Listed Building or is in a Conservation Areas. 
<br/>

In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors most notably the time it has been ran in its current form.
<br/>

If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>`,
  Scotland: `**Scotland**

You will only need planning permission for an HMO in scotland if it is seen as a material change of use.
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area.`,
};

const article4Markdown = {
  England: `**England**

We have checked Article 4 HMO and Sui Generis HMO Planning permission and it looks like Article 4 planning permission may be required. 
<br/>
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors, most notably how long it has been operated in its current form and whether you can prove it.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Wales: `**Wales**

We have checked HMO and Sui Generis HMO Planning permission and it looks like HMO planning permission may be required. 

There is no permitted development rights in Wales to move from C3 family home to C4 small HMO.
<br/>
There are many types of planning permission and we have only checked the ones pertaining to HMOs, other examples could be, but not limited to, if you have made any modifications to the property pr the property is a Listed Building or is in a Conservation Areas. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors, most notably the time it has been ran in its current form.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Scotland: `**Scotland**

You will only need planning permission for an HMO in scotland if it is seen as a material change of use.
<br/>
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area.
`,
};

const suiGenerisMarkdown = {
  England: `**England**

We have checked Article 4 HMO and Sui Generis HMO Planning permission and it looks like Sui Generis planning permission may be required. 
<br/>
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors, most notably how long it has been operated in its current form and whether you can prove it.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Wales: `**Wales**

We have checked HMO and Sui Generis HMO Planning permission and it looks like Sui Generis planning permission may be required. 

There is no permitted development rights in Wales to move from C3 family home to C4 small HMO.
<br/>
There are many types of planning permission and we have only check the ones pertaining to HMOs, other examples could be, but not limited to, if you have made any modifications to the property pr the property is a Listed Building or is in a Conservation Areas. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors most notably the time it has been ran in its current form.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Scotland: `**Scotland**

You will only need planning permission for an HMO in scotland if it is seen as a material change of use.
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area. 
`,
};

const councilFee = `As part of the application process, the council will charge an application fee. 
<br/>
The change of use fee for England is displayed here, though it can vary between country and type of planning case.`;

const noArticle4NoSui = {
  England: `
**England**

We have checked Article 4 HMO and Sui Generis Planning permission for HMOs and it is not required.
<br/>
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors, most notably how long it has been operated in its current form and whether you can prove it.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Wales: `**Wales**

We have checked HMO Planning permission and Sui Generis Planning permission for HMOs and it is not required. 
<br/>
There are many types of planning permission and we have only check the ones pertaining to HMOs, other examples could be, but not limited to, if you have made any modifications to the property pr the property is a Listed Building or is in a Conservation Areas. 
<br/>
In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors most notably the time it has been ran in its current form.
<br/>
If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.
<br/>
If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building'. 
<br/>`,
  Scotland: `**Scotland**

You will only need planning permission for an HMO in Scotland if it is seen as a material change of use.
<br/>
There are many types of planning permission and we have only checked those pertaining to HMOs, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area. `,
};

const noShortTermLetAgreement = {
  England: `**England**
<br />
We have checked Short-term Let Planning Permission requirements and, based on the information you have entered, it is not required.
<br />
There are many types of planning permission and we have only checked those pertaining to short-term lets, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area.
<br />
If you require expert advice, contact an RTPI-registered Town Planner regarding your specific situation. `,
  Scotland: `**Scotland**
<br />
We have checked Short-term Let Planning Permission requirements and, based on the information you have entered, it is not required.
<br />
There are many types of planning permission and we have only checked those pertaining to short-term lets, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area.
<br />
If you require expert advice, contact an RTPI-registered Town Planner regarding your specific situation. `,
  Wales: `**Wales**
<br />
We have checked Short-term Let Planning Permission requirements and, based on the information you have entered, it is not required.
<br />
There are many types of planning permission and we have only checked those pertaining to short-term lets, other examples could apply. Other types include, but are not limited to, permission for modifications to the property, for a listed building, or for a property in a Conservation Area.
<br />
If you require expert advice, contact an RTPI-registered Town Planner regarding your specific situation. `,
};

const Planning = (props) => {
  const formRef = useRef(null);
  const guidelines = usePropertyGuidelines();
  const propertyDetails = usePropertyDetails();
  const { updatePropertyInfo } = usePropertyUpdate();
  const propertyLicenceActions = usePropertyLicenceActions();
  const { canViewPremiumData } = usePropertyAllowedActions();
  const { openPackageModal } = usePropertyUpdate();
  const user = useUserData();

  const onSponsorSelected = (data) => {
    updatePropertyInfo({ ...propertyDetails, ...data });
  };

  const [
    ,
    ,
    sponsorsForProperty,
    onConfirmCallBack,
    onSubmitSponsorSuggestionCallback,
    ,
  ] = usePickSponsor({
    user,
    propertyId: propertyDetails.propertyId,
    onSponsorPicked: onSponsorSelected,
    onSuggestionSubmitted: onSponsorSelected,
  });

  const { openSponsorModal } = usePropertySponsorContext();

  useWhyDidYouUpdate("Planning", props, propertyDetails);

  const [MarkdownText, setMarkdownText] = useState();

  useEffect(() => {
    if (propertyDetails.countryOnsCode) {
      if (propertyDetails.countryOnsCode[0] === "E") {
        return setMarkdownText("England");
      }
      if (propertyDetails.countryOnsCode[0] === "W") {
        return setMarkdownText("Wales");
      }
      if (propertyDetails.countryOnsCode[0] === "S") {
        return setMarkdownText("Scotland");
      }
    } else {
      return setMarkdownText("England");
    }
  }, [propertyDetails.countryOnsCode]);

  const getNoPlanningText = (tenancyType) => {
    switch (tenancyType) {
      case "Short-term Let Agreement":
        return noShortTermLetAgreement[MarkdownText];
      default:
        return noArticle4NoSui[MarkdownText];
    }
  };

  return (
    <div className={styles.layout}>
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName={props.nextName}
      >
        {canViewPremiumData && (
          <>
            <div className={styles.container}>
              {!propertyDetails.planning.length && (
                <Markdown
                  information={getNoPlanningText(propertyDetails.tenancyType)}
                />
              )}
              {propertyDetails.propertyType === "Building" && (
                <Markdown information={buildingOnlyMarkdown[MarkdownText]} />
              )}
              {propertyDetails.propertyType !== "Building" &&
                !!propertyDetails.planning.length &&
                propertyDetails.planning.map((planning) => (
                  <PlanningView
                    title={planning.label}
                    package={{
                      price: planning.cost,
                      currentPackage: "Planning Discovery",
                    }}
                    description={planningText.planning(planning.label)}
                    article4Markdown={article4Markdown[MarkdownText]}
                    suiGenerisMarkdown={suiGenerisMarkdown[MarkdownText]}
                    documentTypes={documentTypes}
                    licence={{
                      documents: planning.documents,
                      id: planning.propertyItemId,
                      actions: propertyLicenceActions,
                    }}
                    canUploadFiles={canViewPremiumData}
                    openPackageModal={openPackageModal}
                  />
                ))}
            </div>
            {!!guidelines && (
              <LicencesList
                planningPage={true}
                loading={!guidelines}
                propertyDetails={propertyDetails}
                formRef={formRef}
                licences={guidelines}
                canViewPremiumData={true}
                openSponsorModal={openSponsorModal}
              />
            )}
          </>
        )}

        {!canViewPremiumData && (
          <div className={styles.upgrade}>
            <SponsorIcon />
            <span className={styles.title}>
              <Typography as="h1">
                It looks like you don't have a sponsor yet.
              </Typography>
            </span>
            <span className={styles.noSponsorsSubtitle}>
              <Typography as="h3">Lets get you a free upgrade!</Typography>
            </span>

            <Upgrade onClick={openSponsorModal} />
          </div>
          // <PickSponsor
          //   sponsors={sponsorsForProperty}
          //   onConfirm={onConfirmCallBack}
          //   onSubmitSponsorSuggestions={onSubmitSponsorSuggestionCallback}
          // />
        )}
      </JourneyLayout>
    </div>
  );
};

Planning.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};

export default Planning;
