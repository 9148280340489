export const mortgageMarkdown = `
Most property journeys start and end with finance, however our aim is to build something well beyond the average journey.

Mortgages, as we see it, are very specific not only to you, but also to the way the property is being operated.
<br/>
Lenders take many different things into consideration, for example: 
- Whether you are meeting the definition of an HMO
- Type of tenancy agreement(s) in place (e.g. single vs multiple)
- Whether there are more than 5 or 7 people in the property
- Whether licensing or planning applies to your property. (see relevant section of your journey)

If you are required to go through a licensing process, your lender is likely to be notified as an 'interested party'. 
<br/>
We have partnered with a mortgage specialist for you to chat to for free, should you need them.

<Providers providers={props.providers}/>
`;

export const insuranceMarkdown = `
Having the right insurance is important to reduce the risks that come with being a landlord or investor. 
<br/>
Insurers take many different things into consideration, for example: 
- Whether you are meeting the definition of an HMO
- Type of tenancy agreement(s) in place (e.g. single vs multiple)
- Whether there are more than 5 or 7 people in the property
- Whether licensing or planning applies to your property. (see relevant section of your journey)

There are a number of great products on the market for property and rent-guarantee insurance, make sure to do some research to find the best one for you - we hope this helps!
<br />

<Image src={props.imageUrl} />
<Providers providers={props.providers}/>
`;
export const developmentUpgradeMarkdown = `
When it comes to considering developing or upgrading a property, having access to the right advice and funding when you require it could make or break a good commercial decision. 
<br/>
We have partnered with a property finance specialist for you to discuss the ins and outs of your specific circumstances with, should you need to.
<Providers providers={props.providers}/>
`;
export const financialAdviceMarkdown = `
Not everyone needs detailed financial advice. However, for those landlords and investors who are interested in understanding their investment opportunities better, there are a number of property-specific financial advisors who we would recommend consulting.
<Providers providers={props.providers}/>
`;

export const fireMarkdown = `
The most important part of fire proofing any property is the Fire Safety Risk Assessment (FSRA). Without it, landlords and investors leave themselves open to risking the lives of tenants and face significant risk of getting the specification of the property wrong, which can cost thousands to redo.<br/>
<br/>
The 'Responsible Person' is required to assess the risk of fire; this assessment should always be carried out by a competent person. Once the FSRA is complete, the 'Responsible Person' should then instruct competent contractors or companies to take care of any fire-related upgrades to the property to reduce the risk of fire and ensure procedures are in place regarding what to do in the event of a fire.  <br/>
<br/>
#### A Fire (Safety) Risk Assessment will:
1. Identify the fire hazards.
2. Identify people at risk.
3. Evaluate, remove or reduce the risks.
4. Record findings, prepare an emergency plan and request training where required.
<br/>
The Fire Safety Risk Assessment must be ‘suitable and sufficient’ for the type of property and all upgrades should be ‘reasonable and practical’. Sounds simple right? Maybe not - and to make things more complicated - fire safety regulations are constantly changing, making them hard to stay on top of. <br/>
<br/>
<Notes>
- Building cladding risk assessments are usually carried out separately and testing of materials in a laboratory is usually required.
- The Building Safety Act 2022 applies to “higher risk buildings” which are 18m+ in height (or 7+ storeys) and comprising at least two residential units. This, together with the  Fire Safety (England) Regulations 2022 imposes the strictest safety requirements on residential buildings because they are most likely to face significant consequences from the spread of fire and structural failure.
- A written FSRA is not necessarily required for properties let to families (including couples and single tenants), non-HMOs or properties that do not include common parts. If you are unsure, seek advice from an expert - such as a qualified Fire Risk Assessor.
</Notes>
<br/>
<PDFDisplay url={props.fireAssessmentUrl}/>
<Providers providers={props.providers}/>
<Disclaimer country={"England"}/>
<br/><br/>
## Fire Detection & Alarm Systems
<br/>
<Divider />
<br/>
Fire detection and alarm systems are categorised into three levels of protection: LD1, LD2 and LD3.
<br/>
<TextImageBox src={props.LD1} >
###### LD1 Maximum Protection
<br/>
Provides maximum protection covering escape routes, high-risk rooms and areas where fires might start. Suitable for all occupants and premesis.
<br/>
#### Areas Covered:
<br/>
#### Hallway, Landing, Living Room, Kitchen (Heat Alarm), Bedroom, Airing / Meter Cupboards, Loft, Garage
</TextImageBox>
<br/>
<TextImageBox src={props.LD2} >
###### LD2 Additional Protection
<br/>
Offers additional protection covering escape routes and high-risk rooms. LD2 is now the minimum requirement for all rented properties and must include a heat alarm in the kitchen and a smoke alarm in principle habitable room(s).
<br/>
#### Areas Covered:
<br/>
#### Hallway, Landing, Living Room, Kitchen (Heat Alarm)
</TextImageBox>
<br/>
<TextImageBox src={props.LD3} >
###### LD3 Minimum Protection
<br/>
Provides minimal protection covering escape routes only. Applicable to specific owner-occupied properties.
<br/>
#### Areas Covered:
<br/>
#### Hallway, Landing
</TextImageBox>
`;

export const fireMarkdownWales = `
The most important part of fire proofing any property is the Fire Safety Risk Assessment (FSRA). Without it, landlords and investors leave themselves open to risking the lives of tenants and face significant risk of getting the specification of the property wrong, which can cost thousands to redo.<br/>
<br/>
The 'Responsible Person' is required to assess the risk of fire; this assessment should always be carried out by a competent person. Once the FSRA is complete, the 'Responsible Person' should then instruct competent contractors or companies to take care of any fire-related upgrades to the property to reduce the risk of fire and ensure procedures are in place regarding what to do in the event of a fire.  <br/>
<br/>
#### A Fire (Safety) Risk Assessment will:
1. Identify the fire hazards.
2. Identify people at risk.
3. Evaluate, remove or reduce the risks.
4. Record findings, prepare an emergency plan and request training where required.
<br/>
The Fire Safety Risk Assessment must be ‘suitable and sufficient’ for the type of property and all upgrades should be ‘reasonable and practical’. Sounds simple right? Maybe not - and to make things more complicated - fire safety regulations are constantly changing, making them hard to stay on top of. <br/>
<br/>
<Notes>
- Building cladding risk assessments are usually carried out separately and testing of materials in a laboratory is usually required.
- The Building Safety Act 2022 largely applies to Wales - although the Welsh Government has proposed some differences in implementation - such as the Act applying to ‘all multi-occupied residential buildings in Wales which contain two or more residential units regardless of height’. The Act imposes the strictest safety requirements on residential buildings because they are most likely to face significant consequences from the spread of fire and structural failure.
- A written FSRA is not necessarily required for properties let to families (including couples and single tenants), non-HMOs or properties that do not include common parts. If you are unsure, seek advice from an expert - such as a qualified Fire Risk Assessor.
</Notes>
<br/>
<PDFDisplay url={props.fireAssessmentUrl}/>
<Providers providers={props.providers}/>
<Disclaimer country={"Wales"}/>
<br/><br/>
## Fire Detection & Alarm Systems
<br/>
<Divider />
<br/>
Fire detection and alarm systems are categorised into three levels of protection: LD1, LD2 and LD3.
<br/>
<TextImageBox src={props.LD1} >
###### LD1 Maximum Protection
<br/>
Provides maximum protection covering escape routes, high-risk rooms and areas where fires might start. Suitable for all occupants and premesis.
<br/>
#### Areas Covered:
<br/>
#### Hallway, Landing, Living Room, Kitchen (Heat Alarm), Bedroom, Airing / Meter Cupboards, Loft, Garage
</TextImageBox>
<br/>
<TextImageBox src={props.LD2} >
###### LD2 Additional Protection
<br/>
Offers additional protection covering escape routes and high-risk rooms. LD2 is now the minimum requirement for all rented properties and must include a heat alarm in the kitchen and a smoke alarm in principle habitable room(s).
`;

export const fireMarkdownScotland = `
The most important part of fire proofing any property is the Fire Safety Risk Assessment (FSRA). Without it, landlords and investors leave themselves open to risking the lives of tenants and face significant risk of getting the specification of the property wrong, which can cost thousands to redo.<br/>
<br/>
The ‘dutyholder’ is required to assess the risk of fire; this assessment should always be carried out by a competent person. Once the FSRA is complete, the ‘dutyholder’ should then instruct competent contractors or companies to take care of any fire-related upgrades to the property to reduce the risk of fire and ensure procedures are in place regarding what to do in the event of a fire.  <br/>
<br/>
#### A Fire (Safety) Risk Assessment will:
1. Identify the fire hazards.
2. Identify people at risk.
3. Evaluate, remove or reduce the risks.
4. Record findings, prepare an emergency plan and request training where required.
<br/>
The Fire Safety Risk Assessment must “identify any risks to the safety” of relevant persons and to put in place appropriate fire safety measures. Sounds simple right? Maybe not - and to make things more complicated - fire safety regulations are constantly changing, making them hard to stay on top of. <br/>
<br/>
<Notes>
- Building cladding risk assessments are usually carried out separately and testing of materials in a laboratory is usually required.
- Some parts of the Building Safety Act 2022 (such as those relating to construction and cladding products) apply in Scotland. However, many of the issues covered by the BSA are devolved matters that the Scottish Parliament has addressed through legislation such as the Building (Scotland) Act 2003 and the Housing (Cladding Remediation) (Scotland) Bill. These impose the strictest safety requirements on residential buildings because they are most likely to face significant consequences from the spread of fire and structural failure.
- A written FSRA is not necessarily required for properties let to families (including couples and single tenants), non-HMOs or properties that do not include common parts. If you are unsure, seek advice from an expert - such as a qualified Fire Risk Assessor.
</Notes>
<br/>
<PDFDisplay url={props.fireAssessmentUrl}/>
<Providers providers={props.providers}/>
<Disclaimer country={"Scotland"}/>
<br/><br/>
## Fire Detection & Alarm Systems
<br/>
<Divider />
<br/>
Fire detection and alarm systems are categorised into three levels of protection: LD1, LD2 and LD3.
<br/>
<TextImageBox src={props.LD1} >
###### LD1 Maximum Protection
<br/>
Provides maximum protection covering escape routes, high-risk rooms and areas where fires might start. Suitable for all occupants and premesis.
<br/>
#### Areas Covered:
<br/>
#### Hallway, Landing, Living Room, Kitchen (Heat Alarm), Bedroom, Airing / Meter Cupboards, Loft, Garage
</TextImageBox>
<br/>
<TextImageBox src={props.LD2} >
###### LD2 Additional Protection
<br/>
Offers additional protection covering escape routes and high-risk rooms. LD2 is now the minimum requirement for all rented properties and must include a heat alarm in the kitchen and a smoke alarm in principle habitable room(s).
`;

export const energyMarkdown = `
<EPCGraph />
From 1 April 2023, all properties in England and Wales being sold or let (whether new or continuing tenancies) must have a minimum Energy Performance Certificate (EPC) rating of E, unless a valid exemption is in place.
<br />
The newly-elected Labour Government have confirmed that all rental properties will need an EPC rating of C or better by 2028.
<br />
The Government is encouraging PRS homeowners to upgrade the energy efficiency of their properties as soon as possible, if needed. It is currently supporting homeowners through grants to assist in the transition to gas boilers and low-carbon heat pumps. The Boiler Upgrade Grant, for example, has been raised by 50%, from £5,000 to £7,500. Landlords will also have additional time to make the switch, as the deadline for phasing out fossil fuel boilers has been extended to 2035 – delaying it from 2026.
<br />
With the upcoming changes in legislation, those landlords who are investing in modern, energy-efficient properties will undoubtedly be putting themselves in a good position to attract tenants and meet government standards - ensuring a sustainable rental income for the future.
<br />
If you are looking to understand your starting point on your journey of going green, visit the certification section to obtain an up-to-date EPC of your property(s).
<br />
If you are interested in finding out more about green mortgage products, we would advise speaking to your mortgage advisor or lender.
<Providers providers={props.providers}/>
`;

export const energyMarkdownWales = `
<EPCGraph />
From 1 April 2023, all properties in England and Wales being sold or let (whether new or continuing tenancies) must have a minimum Energy Performance Certificate (EPC) rating of E, unless a valid exemption is in place.
<br />
The newly-elected Labour Government have confirmed that all rental properties will need an EPC rating of C or better by 2028.
<br />
The Government is encouraging PRS homeowners to upgrade the energy efficiency of their properties as soon as possible, if needed. It is currently supporting homeowners through grants to assist in the transition to gas boilers and low-carbon heat pumps. The Boiler Upgrade Grant, for example, has been raised by 50%, from £5,000 to £7,500. Landlords will also have additional time to make the switch, as the deadline for phasing out fossil fuel boilers has been extended to 2035 – delaying it from 2026.
<br />
With the upcoming changes in legislation, those landlords who are investing in modern, energy-efficient properties will undoubtedly be putting themselves in a good position to attract tenants and meet government standards - ensuring a sustainable rental income for the future.
<br />
If you are looking to understand your starting point on your journey of going green, visit the certification section to obtain an up-to-date EPC of your property(s).
<br />
If you are interested in finding out more about green mortgage products, we would advise speaking to your mortgage advisor or lender.
<Providers providers={props.providers}/>
`;

export const energyMarkdownScotland = `
<EPCGraph />
In Scotland, there is currently no minimum EPC rating required to let or sell property. However, the Scottish Government is aiming to mandate all residential properties achieving an EPC rating of at least a C by 2033, "where technically and legally feasible and cost-effective".
<br />
For the PRS, the Scottish Government is currently proposing to introduce legislation in 2025 which will require all privately rented properties to attain a minimum EPC rating of a C, "where technically feasible and cost-effective, by 2028."
<br />
With the upcoming changes in legislation, those landlords who are investing in modern, energy-efficient properties will undoubtedly be putting themselves in a good position to attract tenants and meet government standards - ensuring a sustainable rental income for the future.
<br />
If you are looking to understand your starting point on your journey of going green, visit the certification section to obtain an up-to-date EPC of your property(s).
<br />
If you are interested in finding out more about green mortgage products, we would advise speaking to your mortgage advisor or lender.
<Providers providers={props.providers}/>
`;

export const designMarkdown = `
The design of any property depends on how it is going to be tenanted and occupied, which can range from a short let to a shared tenancy to individual lets and many more. Ultimately, it comes down to the end customer, the tenant, and how they are going to occupy the property.
<br/>
Unfortunetly legislation has a huge impact on the design and layout of a property, right down to the size of rooms. When starting the design journey, it is critical to ensure that you are aware of both national and local council minimum standards. We would recommend using a design expert or company as we have found that even inside the same council the planning and licensing teams have different room sizing requirements!
<br/>
You should also consider how a property will be managed and what you intend to do with the property over the next 10-20 years, as the design can be informed and optimised by these factors; a good architect will take this into account.
<br/>
We have partnered with an architectural firm that can help, should you need it.

<Providers providers={props.providers}/>
<Image src={props.imageUrl} />`;

export const taxMarkdown = `
There are multiple tax considerations when investing in property and numerous strategies to consider, such as investing in your own name or as a Limited Company. This is made even more complex as tax legislation is changing. 
<br/>
There are a number of property-specific tax advisors in the industry who we would recommend consulting.
<Providers providers={props.providers}/>
`;

export const markdownContent = {
  mortgage: {
    markdown: mortgageMarkdown,
    props: {
      providers: [
        {
          name: "Mortgage Advice Bureau",
          description:
            "With over 1,600 advisers across the UK, we offer expert mortgage advice on a local, regional and national level to UK consumers, both face-to-face and over the phone.",
          logoUrl:
            "https://freemium-bucket.s3.eu-west-2.amazonaws.com/mortgage-advice-bureau.png",
          iframe: "https://hello.mortgageadvicebureau.com/yuno/",
          title: "Mortgage",
        },
      ],
    },
  },
  "mortgage-scotland": {
    markdown: mortgageMarkdown,
    props: {
      providers: [
        {
          name: "Mortgage Advice Bureau",
          description:
            "With over 1,600 advisers across the UK, we offer expert mortgage advice on a local, regional and national level to UK consumers, both face-to-face and over the phone.",
          logoUrl:
            "https://freemium-bucket.s3.eu-west-2.amazonaws.com/mortgage-advice-bureau.png",
          iframe: "https://hello.mortgageadvicebureau.com/yuno/",
          title: "Mortgage",
        },
      ],
    },
  },
  insurance: {
    markdown: insuranceMarkdown,
    props: {
      imageUrl:
        "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/Advanced+Rent.png",
      providers: [
        {
          name: "Need some support...",
          title: "Insurance",
        },
      ],
    },
  },
  "insurance-scotland": {
    markdown: insuranceMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          title: "Insurance",
        },
      ],
    },
  },
  "development-upgrade": {
    markdown: developmentUpgradeMarkdown,
    props: {
      providers: [
        {
          name: "Blaise Commercial Finance",
          description:
            "Blaise Commercial Finance is an experienced team of Commercial Banking and Development Finance specialists. We'll work closely with you to find the best funding solution possible, through our extensive network of lenders. We are here to be your trusted Partner for Finance.",
          logoUrl:
            "https://freemium-bucket.s3.eu-west-2.amazonaws.com/blaise.png",
          title: "Development / Upgrade",
        },
      ],
    },
  },
  "development-upgrade-scotland": {
    markdown: developmentUpgradeMarkdown,
    props: {
      providers: [
        {
          name: "Blaise Commercial Finance",
          description:
            "Blaise Commercial Finance is an experienced team of Commercial Banking and Development Finance specialists. We'll work closely with you to find the best funding solution possible, through our extensive network of lenders. We are here to be your trusted Partner for Finance.",
          logoUrl:
            "https://freemium-bucket.s3.eu-west-2.amazonaws.com/blaise.png",
          title: "Development / Upgrade",
        },
      ],
    },
  },
  "financial-advice": {
    markdown: financialAdviceMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          title: "Financial Advice",
        },
      ],
    },
  },
  "financial-advice-scotland": {
    markdown: financialAdviceMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          title: "Financial Advice",
        },
      ],
    },
  },
  "fire-safety": {
    markdown: fireMarkdown,
    props: {
      text: [
        {
          title: "Why do I need an FSRA?",
          title: "Fire Safety",
          text: "The most important part of any fire journey is the Fire Safety Risk Assessment (FSRA), without it we are leaving ourselves open to huge amounts of risk of getting the specification of the property wrong costing thousands to redo, risking lives and of course our largest investments. Fire safety regulation is constantly changing and hard to stay on top off",
        },
        {
          text: "Below is a link to an example FSRA",
          link: {
            url: "https://freemium-bucket.s3.eu-west-2.amazonaws.com/Example+FSRA+template.pdf",
            type: "_blank",
            text: "Example FSRA",
          },
        },
      ],
      LD1: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD1.png",
      LD2: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD2.png",
      LD3: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD3.png",
    },
  },
  "fire-safety-wales": {
    markdown: fireMarkdownWales,
    props: {
      text: [
        {
          title: "Why do I need an FSRA?",
          title: "Fire Safety",
          text: "The most important part of any fire journey is the Fire Safety Risk Assessment (FSRA), without it we are leaving ourselves open to huge amounts of risk of getting the specification of the property wrong costing thousands to redo, risking lives and of course our largest investments. Fire safety regulation is constantly changing and hard to stay on top off",
        },
        {
          text: "Below is a link to an example FSRA",
          link: {
            url: "https://freemium-bucket.s3.eu-west-2.amazonaws.com/Example+FSRA+template.pdf",
            type: "_blank",
            text: "Example FSRA",
          },
        },
      ],
      LD1: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD1.png",
      LD2: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD2.png",
      LD3: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD3.png",
    },
  },
  "fire-safety-scotland": {
    markdown: fireMarkdownScotland,
    props: {
      text: [
        {
          title: "Why do I need an FSRA?",
          title: "Fire Safety",
          text: "The most important part of any fire journey is the Fire Safety Risk Assessment (FSRA), without it we are leaving ourselves open to huge amounts of risk of getting the specification of the property wrong costing thousands to redo, risking lives and of course our largest investments. Fire safety regulation is constantly changing and hard to stay on top off",
        },
        {
          text: "Below is a link to an example FSRA",
          link: {
            url: "https://freemium-bucket.s3.eu-west-2.amazonaws.com/Example+FSRA+template.pdf",
            type: "_blank",
            text: "Example FSRA",
          },
        },
      ],
      LD1: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD1.png",
      LD2: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD2.png",
      LD3: "https://yuno-production.s3.eu-west-2.amazonaws.com/assets/fsra/LD3.png",
    },
  },
  "going-green": {
    markdown: energyMarkdown,
    props: {
      providers: [
        {
          name: "",
          title: "Going Green",
        },
      ],
    },
  },
  "going-green-wales": {
    markdown: energyMarkdownWales,
    props: {
      providers: [
        {
          name: "",
          title: "Going Green",
        },
      ],
    },
  },
  "going-green-scotland": {
    markdown: energyMarkdownScotland,
    props: {
      providers: [
        {
          name: "",
          title: "Going Green",
        },
      ],
    },
  },
  design: {
    markdown: designMarkdown,
    props: {
      imageUrl: "",
      providers: [
        {
          name: "Aura Homes",
          description:
            "We’re experts in cutting-edge home design. And our meticulous attention to detail means we can meet even the most challenging of briefs.",
          logoUrl:
            "https://www.aurahomes.co.uk/wp-content/uploads/2020/04/aurahomes-logo.png",
          title: "Design",
        },
      ],
    },
  },
  "design-scotland": {
    markdown: designMarkdown,
    props: {
      imageUrl: "",
      providers: [
        {
          name: "Aura Homes",
          description:
            "We’re experts in cutting-edge home design. And our meticulous attention to detail means we can meet even the most challenging of briefs.",
          logoUrl:
            "https://www.aurahomes.co.uk/wp-content/uploads/2020/04/aurahomes-logo.png",
          title: "Design",
        },
      ],
    },
  },
  tax: {
    markdown: taxMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          title: "Tax",
        },
      ],
    },
  },
  "tax-scotland": {
    markdown: taxMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          title: "Tax",
        },
      ],
    },
  },
};

export const markdownContentAspire = {
  mortgage: {
    markdown: mortgageMarkdown,
    props: {
      providers: [
        {
          name: "Aspire",
          url: "https://www.aspire.co.uk/",
          description:
            "We’re known for our superior service and ability to provide clients with a tailored experience.",
          logoUrl:
            "https://www.aspire.co.uk/images/logo.gif.pagespeed.ce.fumoamzON6.gif",
        },
      ],
    },
  },
  insurance: {
    markdown: insuranceMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          url: "https://www.aspire.co.uk/",
        },
      ],
    },
  },
  "development-upgrade": {
    markdown: developmentUpgradeMarkdown,
    props: {
      providers: [
        {
          name: "Aspire",
          url: "https://www.aspire.co.uk/",
          description:
            "We offer on the ground insight and advice that is regularly converted into profitable results.",
          logoUrl:
            "https://www.aspire.co.uk/images/logo.gif.pagespeed.ce.fumoamzON6.gif",
        },
      ],
    },
  },
  "financial-advice": {
    markdown: financialAdviceMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          url: "https://www.aspire.co.uk/",
        },
      ],
    },
  },
  "fire-safety": {
    markdown: fireMarkdown,
    props: {
      text: [
        {
          title: "Why do I need an FSRA?",
          url: "https://www.aspire.co.uk/",
          text: "The most important part of any fire journey is the Fire Safety Risk Assessment (FSRA), without it we are leaving ourselves open to huge amounts of risk of getting the specification of the property wrong costing thousands to redo, risking lives and of course our largest investments. Fire safety regulation is constantly changing and hard to stay on top off",
        },
        {
          text: "Below is a link to an example FSRA",
          link: {
            url: "https://freemium-bucket.s3.eu-west-2.amazonaws.com/Example+FSRA+template.pdf",
            type: "_blank",
            text: "Example FSRA",
          },
        },
      ],
    },
  },
  "going-green": {
    markdown: energyMarkdown,
    props: {
      providers: [
        {
          name: "",
          url: "https://www.aspire.co.uk/",
        },
      ],
    },
  },
  design: {
    markdown: designMarkdown,
    props: {
      imageUrl: "",
      providers: [
        {
          name: "Aspire",
          url: "https://www.aspire.co.uk/",
          description:
            "We’re experts in cutting-edge home design. And our meticulous attention to detail means we can meet even the most challenging of briefs.",
          logoUrl:
            "https://www.aspire.co.uk/images/logo.gif.pagespeed.ce.fumoamzON6.gif",
        },
      ],
    },
  },
  tax: {
    markdown: taxMarkdown,
    props: {
      providers: [
        {
          name: "Need some support...",
          url: "https://www.aspire.co.uk/",
        },
      ],
    },
  },
};
