import {
  usePropertyAnalyticsActionsContext,
  usePropertyDetails,
} from "context/propertyContext/PropertyContext";
import propTypes from "prop-types";

import { Image, Typography } from "v2/components";
import EPCGraph from "v2/components/EPCGraph/EPCGraph";
import Flex from "v2/components/Layout/Flex";
import Markdown from "v2/components/Markdown/Markdown";
import PDFDisplay from "v2/components/PDFDisplay/PDFDisplay";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import styles from "./Common.module.css";
import InfoDownloads from "./InfoDownloads/InfoDownloads";
import Providers from "./Providers/Providers";

const getDisclaimerByCountry = (country) => {
  switch (country) {
    case "England":
      return "Remember, under the Fire Safety Order 2005, the property owner is usually the 'responsible person'. This person’s responsibility is to instruct a ‘competent person’ and, in the worst-case scenario, be able to defend such a person’s appointment in a legal action. If you are appointing a fire risk assessor on behalf of the ‘responsible person’, you should be equally as diligent. As a minimum, you should look for credentials under bodies such as the IFA and IFM, and for continuous development of skills.";
    case "Wales":
      return "Remember, under the Fire Safety Order 2005, the property owner is usually the 'responsible person'. This person’s responsibility is to instruct a ‘competent person’ and, in the worst-case scenario, be able to defend such a person’s appointment in a legal action. If you are appointing a fire risk assessor on behalf of the ‘responsible person’, you should be equally as diligent. As a minimum, you should look for credentials under bodies such as the IFA and IFM, and for continuous development of skills.";
    case "Scotland":
      return "Remember, under the Fire (Scotland) Act 2005 and the Fire Safety (Scotland) Regulations 2006, the ‘dutyholder’ is the person who “has control to any extent of relevant premises”. This is usually property managers or landlords/owners, but may also include other staff or volunteers. It is the responsibility of this person to instruct a ‘competent person’ to carry out a ‘suitable and sufficient’ fire safety risk assessment. As a minimum, you should look for credentials under bodies such as the IFA or IFM, and continuous development of skills.";
  }
};

const Disclaimer = (props) => {
  return (
    <Flex>
      <span className={styles.disclaimer}>
        <b>Important</b>
        <br />
        <Typography as="noteText">
          {getDisclaimerByCountry(props.country)}
        </Typography>
      </span>
    </Flex>
  );
};

const Notes = (props) => {
  return (
    <Flex>
      <span className={styles.notes}>
        <Typography as="h6">Notes</Typography>
        <Typography as="noteText">{props.children}</Typography>
      </span>
    </Flex>
  );
};

const TextLink = (props) => {
  return (
    <a href={props.to}>
      <Typography as="link">{props.children}</Typography>
    </a>
  );
};

const Divider = () => {
  return <div className={styles.divider}></div>;
};

const TextImageBox = (props) => {
  return (
    <div className={styles.textImageBoxContainer}>
      <div className={styles.textImageBoxText}>{props.children}</div>
      <div className={styles.textImageBoxImage}>
        <img src={props.src}></img>
      </div>
    </div>
  );
};

function Common(props) {
  const propertyDetails = usePropertyDetails();
  const { epc } = usePropertyAnalyticsActionsContext();
  const components = {
    Disclaimer,
    Providers,
    Image,
    PDFDisplay,
    EPCGraph: (props) => <EPCGraph {...props} {...epc} />,
    InfoDownloads,
    Notes,
    TextLink,
    Divider,
    TextImageBox,
  };
  const markdown = propertyDetails.markdownContent[props.path]?.markdown;
  const markdownAspire = propertyDetails.markdownContent[props.path]?.markdown;
  return (
    <JourneyLayout
      name={props.name}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      nextName={props.nextName}
    >
      <div className={styles.markdownContainer}>
        {!!markdownAspire &&
          window.location.origin === "https://aspire.goyuno.com" && (
            <Markdown
              information={
                propertyDetails.markdownContentAspire[props.path].markdown
              }
              components={components}
              contentProps={{
                ...propertyDetails.markdownContentAspire[props.path].props,
              }}
            />
          )}
        {!!markdown &&
          window.location.origin !== "https://aspire.goyuno.com" && (
            <Markdown
              information={propertyDetails.markdownContent[props.path].markdown}
              components={components}
              contentProps={{
                ...propertyDetails.markdownContent[props.path].props,
              }}
            />
          )}
      </div>
    </JourneyLayout>
  );
}

Common.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};

export default Common;
