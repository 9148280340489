import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import styles from "./PhoneNumberV3.module.css";
import Label from "../Label";
import CountryCodes from "./countryCodes.json";

const PhoneNumberV3 = (props) => {
  useWhyDidYouUpdate(`Input ${props.name}`, props);
  return (
    <Label
      label={props.label}
      onInputTip={props.onInputTip}
      error={props.error}
      noSponsor={props.noSponsor}
    >
      <span
        className={`${styles.container} ${
          props.error ? styles.inputError : ""
        }`}
      >
        <div className={styles.inputContainer}>
          <select
            className={`
            ${styles.countryCodeSelect}
            ${props.error ? styles.inputError : ""}
            `}
            defaultValue="+44"
            onChange={props.selectChange}
          >
            {CountryCodes.map((cc) => {
              return <option value={cc.countryCode}>{cc.text}</option>;
            })}
          </select>
          <input
            autoComplete={props.autocomplete ? "on" : "off"}
            id={props.id || ""}
            readOnly={props.readOnly}
            type={props.type}
            placeholder={props.placeholder}
            name={props.name}
            className={`${
              props.variant ? styles[props.variant] : styles.default
            } 
          ${props.error ? styles.inputError : ""}
          `}
            value={props.value}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            min={props.min}
            max={props.max}
            disabled={props.disabled}
          />
        </div>
      </span>
    </Label>
  );
};

PhoneNumberV3.propTypes = {
  label: propTypes.string,
  onInputTip: propTypes.func,
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onKeyDown: propTypes.func,
  className: propTypes.string,
  readOnly: propTypes.bool,
  autocomplete: propTypes.bool,
  selectChange: propTypes.func,
};

PhoneNumberV3.defaultProps = {
  type: "text",
  placeholder: "text",
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  onKeyDown: () => null,
  className: "",
  readOnly: false,
  autocomplete: true,
  min: "",
  max: "",
};
export default PhoneNumberV3;
