import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./Input.module.css";

function PhoneNumberInput(props) {
  return (
    <PhoneInput
      className={styles.container}
      placeholder="Enter phone number"
      defaultCountry="GB"
      error={""}
      value={props.value}
      onChange={props.onChange}
    />
  );
}

export default PhoneNumberInput;
