import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { SpinnerCircularFixed } from "spinners-react";
import Typography from "../../Typography/Typography";
import styles from "./Button.module.css";

const Button = ({
  actionName,
  variant = Button.defaultProps.variant,
  size = Button.defaultProps.size,
  loading = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <button
      {...props}
      className={`${styles.container} ${styles[variant]} ${styles[size]} ${
        !props.icon && styles.noIcon
      }`}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <SpinnerCircularFixed
            color="#F2C94C"
            secondaryColor="#ddd"
            size="2rem"
          />
        </div>
      ) : null}
      <div className={loading ? styles.textColour : null}>
        <Typography as="Button">{actionName}</Typography>
      </div>

      {props.icon && props.icon}
    </button>
  );
};

Button.propTypes = {
  id: propTypes.string,
  classes: propTypes.object,
  onClick: propTypes.func,
  children: propTypes.any,
  disabled: propTypes.bool,
  type: propTypes.string,
  className: propTypes.string,
  actionName: propTypes.string.isRequired,
  icon: propTypes.element,
  variant: propTypes.oneOf([
    "primary",
    "secondary",
    "destructive",
    "greyed",
    "outlines",
    "discreet",
    "verifyAddress",
    "bannerButton",
    "noBorder",
  ]),
  size: propTypes.oneOf(["small", "large"]),
};

Button.defaultProps = {
  id: "",
  children: "",
  variant: "primary",
  disabled: false,
  size: "large",
};

export default Button;
