import { useFormik } from "formik";
import propTypes from "prop-types";

import { Button, Form, Label, PhoneNumberInput } from "v2/components/Form";
import ModalV2 from "../Modal";
import styles from "./PhoneNumber.module.css";

const PhoneNumberModal = (props) => {
  const onSubmitCallback = (data) => {
    props.onSubmit(data.phoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    onSubmit: onSubmitCallback,
  });

  const onPhoneNumberChange = (newPhoneNumber) =>
    formik.setFieldValue("phoneNumber", newPhoneNumber);
  return (
    <ModalV2>
      <div className={styles.container}>
        <header>Update your phone number</header>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.formContainer}>
            <Label
              label={"Phone number"}
              error={
                props.error?.details.find(
                  (errorDetail) => errorDetail.param === "phoneNumber"
                )?.msg
              }
            >
              <PhoneNumberInput
                value={formik.values.phoneNumber}
                onChange={onPhoneNumberChange}
              />
            </Label>
            <Button
              actionName="Update my phone number"
              type="submit"
              id="Stonly_Phone_Update"
            />
          </div>
        </Form>
      </div>
    </ModalV2>
  );
};

PhoneNumberModal.protTypes = {
  onSubmit: propTypes.func.isRequired,
  onClose: propTypes.func,
  error: propTypes.arrayOf(
    propTypes.shape({
      msg: propTypes.string,
      param: propTypes.string,
    })
  ),
};

export { PhoneNumberModal };
