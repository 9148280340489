import {
  useCustomer,
  useUserData,
} from "context/environmentContext/EnvironmentContext";
import {
  usePropertyAllowedActions,
  usePropertyDetails,
  usePropertyUpdate,
  usePropertySponsorContext,
} from "context/propertyContext/PropertyContext";
import usePickSponsor from "Hooks/usePickSponsor";
import propTypes from "prop-types";

import { Links, Typography } from "v2/components";
import { Iframe } from "v2/components/Iframe/Iframe";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import styles from "./LettingAndSales.module.css";
import { ReactComponent as Upgrade } from "images/upgrade.svg";
import { ReactComponent as SponsorIcon } from "images/sponsor.svg";
function LettingAndSales(props) {
  const propertyDetails = usePropertyDetails();
  const customer = useCustomer();
  const user = useUserData();
  const { canViewPremiumData } = usePropertyAllowedActions();
  const { updatePropertyInfo } = usePropertyUpdate();
  const onSponsorSelected = (data) => {
    updatePropertyInfo({ ...propertyDetails, ...data });
  };
  const [
    ,
    ,
    sponsorsForProperty,
    onConfirmCallBack,
    onSubmitSponsorSuggestionCallback,
    ,
  ] = usePickSponsor({
    user,
    propertyId: propertyDetails.propertyId,
    onSponsorPicked: onSponsorSelected,
    onSuggestionSubmitted: onSponsorSelected,
  });
  const { openSponsorModal } = usePropertySponsorContext();
  return (
    <span className={styles.container}>
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName="Get Support"
      >
        {!canViewPremiumData && (
          <div className={styles.upgrade}>
            <SponsorIcon />
            <span className={styles.title}>
              <Typography as="h1">
                It looks like you don't have a sponsor yet.
              </Typography>
            </span>
            <span className={styles.noSponsorsSubtitle}>
              <Typography as="h3">Lets get you a free upgrade!</Typography>
            </span>

            <Upgrade onClick={openSponsorModal} />
          </div>
          // <PickSponsor
          //   sponsors={sponsorsForProperty}
          //   onConfirm={onConfirmCallBack}
          //   onSubmitSponsorSuggestions={onSubmitSponsorSuggestionCallback}
          // />
        )}
        {canViewPremiumData &&
          window.location.origin === "https://aspire.goyuno.com" && (
            <>
              {!!propertyDetails.sponsor &&
                !propertyDetails.sponsor?.isIframeable && (
                  <Links
                    as="termsAndConditions"
                    path="https://www.aspire.co.uk/"
                  >
                    GO TO Partner website
                  </Links>
                )}
              {!!propertyDetails.sponsor &&
                propertyDetails.sponsor.isIframeable && (
                  <Iframe
                    id="iframe"
                    title="sponsor"
                    src="https://www.aspire.co.uk/"
                    className={styles.iframe}
                  />
                )}

              {!propertyDetails.sponsor &&
                !!customer.websiteURL &&
                !customer.isIframable && (
                  <Links
                    as="termsAndConditions"
                    path="https://www.aspire.co.uk/"
                  >
                    GO TO Partner website
                  </Links>
                )}
              {!propertyDetails.sponsor &&
                !!customer.websiteURL &&
                customer.isIframable && (
                  <Iframe
                    title="sponsor"
                    src="https://www.aspire.co.uk/"
                    className={styles.iframe}
                  />
                )}
            </>
          )}
        {canViewPremiumData &&
          window.location.origin !== "https://aspire.goyuno.com" && (
            <>
              {!!propertyDetails.sponsor &&
                !propertyDetails.sponsor?.isIframeable && (
                  <Links
                    as="termsAndConditions"
                    path={propertyDetails.sponsor.websiteURL}
                  >
                    GO TO Partner website
                  </Links>
                )}
              {!!propertyDetails.sponsor &&
                propertyDetails.sponsor.isIframeable && (
                  <Iframe
                    id="iframe"
                    title="sponsor"
                    src={propertyDetails.sponsor.websiteURL}
                    className={styles.iframe}
                  />
                )}

              {!propertyDetails.sponsor &&
                !!customer.websiteURL &&
                !customer.isIframable && (
                  <Links as="termsAndConditions" path={customer.websiteURL}>
                    GO TO Partner website
                  </Links>
                )}
              {!propertyDetails.sponsor &&
                !!customer.websiteURL &&
                customer.isIframable && (
                  <Iframe
                    title="sponsor"
                    src={customer.websiteURL}
                    className={styles.iframe}
                  />
                )}
            </>
          )}
      </JourneyLayout>
    </span>
  );
}

LettingAndSales.propTypes = {
  url: propTypes.string,
  name: propTypes.string.isRequired,
  onNext: propTypes.func,
};

LettingAndSales.defaultProps = {
  title: "sponsor",
};

export default LettingAndSales;
