import { getLoginInfo } from "utils";

export const initialState = getLoginInfo();
export const loginForm = {
  email: "",
  password: "",
};

export const signUpForm = {
  email: null,
  password: null,
  firstName: null,
  lastName: null,
  confirmPassword: null,
};
