import { SpinnerCircularFixed } from "spinners-react";
import useModal from "Hooks/useModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../../images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "../../../../images/arrowup.svg";
import { ReactComponent as Info } from "../../../../images/info.svg";
import { Links, ModalV2, PropertyForm } from "v2/components";
import Button from "v2/components/Form/Button/Button";
import Map from "v2/components/Map/Map";
import LicencesList from "v2/containers/Licences";
import styles from "./PropertyInfo.module.css";
import { propertyAnalyticsPath } from "./PropertyInfoConstants";
import { updatePropertyDetails } from "Apis";
import Typography from "v2/components/Typography/Typography";
import { useWindowDimensions } from "utils";
import {
  usePropertyAllowedActions,
  usePropertyAnalyticsActionsContext,
  usePropertyDetails,
  usePropertyGuidelines,
  usePropertySponsorContext,
  usePropertyUpdate,
} from "context/propertyContext/PropertyContext";
import {
  Prompt,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import MapGuideModal from "./MapGuideModal/MapModalGuide";
import SendEmailModal from "./SendEmailModal/SendEmailModal";
import { useUserData } from "context/environmentContext/EnvironmentContext";

const PropertyInfo = (props) => {
  const { width } = useWindowDimensions();
  const [showForm, setShowForm] = useState(false);
  const propertyDetails = usePropertyDetails();
  const userDetails = useUserData();
  const { updatePropertyInfo } = usePropertyUpdate();
  const guidelines = usePropertyGuidelines();
  const { getAnalytics } = usePropertyAnalyticsActionsContext();
  const { readOnly, canViewPremiumData } = usePropertyAllowedActions();
  const { openSponsorModal } = usePropertySponsorContext();
  const { onSelectSponsor } = usePropertySponsorContext();
  const { url } = useRouteMatch();
  const formRef = useRef(null);

  const [isMapModalOpen, openMapModal, closeMapModal, leadMapModalData] =
    useModal();
  const [isEmailModalOpen, openEmailModal, closeEmailModal, emailModalData] =
    useModal();
  const [confirmPin, setConfirmPin] = useState(false);
  const [sendingPin, setSendingPin] = useState(false);
  const [cPError, setCPError] = useState("");
  const [CPV, setCPV] = useState(false);

  const onSaveCallback = async (data) => {
    await updatePropertyInfo(data);
  };

  const onSubmitCallback = useCallback(
    async (data) => {
      await updatePropertyInfo(data);
      props.onNext();
    },
    [updatePropertyInfo, props]
  );

  const [loading, setLoading] = useState(false);

  const onRequestDataAnalytics = (propertyType, analyticName) => {
    if (!propertyType) {
      formRef.current.onMenuOpen();
      return;
    }
    setLoading(true);
    getAnalytics(analyticName);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    props.setActionRequired(propertyDetails.actionRequired);
  }, [propertyDetails.actionRequired]);

  useEffect(() => {
    setLoading(false);
    setConfirmPin(
      !!props.actionRequired?.some(
        (a) => a.value === "missing uprn" || a.value === "confirm pin location"
      )
    );
  }, [props.actionRequired]);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const pif = searchParam.get("pif") === "true";
    setShowForm(pif);
  }, [window.location.search]);

  const toggle = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  const viewButton = (analytics) => {
    const bedrooms = propertyDetails.roomsOrUnits;
    const localHousingBedrooms = bedrooms > 4 ? 4 : bedrooms;
    const lettingBedrooms = bedrooms > 5 ? 5 : bedrooms;
    let filteredAnalytics;
    if (analytics.hasOwnProperty("uprn-title")) {
      filteredAnalytics = Object.values(analytics);
    } else {
      filteredAnalytics = [
        analytics["rents"].filter(
          (r) => r.parameters.bedrooms == lettingBedrooms
        ),
        analytics["yields"].filter(
          (y) => y.parameters.bedrooms == lettingBedrooms
        ),
        analytics["lha-rate"].filter(
          (l) => l.parameters.bedrooms == localHousingBedrooms
        ),
        analytics["rentalizer-summary"].filter(
          (y) => y.data.property_details?.bedrooms == bedrooms
        ),
        analytics["rents-hmo"],
      ];
    }
    return filteredAnalytics.some((a) => a.length === 0);
  };

  const analyticsDataObjects = propertyAnalyticsPath.map((pathObject) => {
    const dataTypes = {};
    pathObject.dataTypes.forEach((dataType) => {
      dataTypes[dataType] = propertyDetails?.analytics
        .filter((analytic) => analytic.dataType === dataType)
        .map(({ data, parameters }) => ({ data: data, parameters }));
    });
    return dataTypes;
  });

  const onNewCoordinate = ({ lat, lng }) => {
    updatePropertyDetails(propertyDetails.uuid, {
      userLongitude: lng,
      userLatitude: lat,
    });
  };

  const cPErrorMessage = async (message) => {
    setCPError(message);
    setCPV(true);
    await new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 5000);
    });
    setCPV(false);
    setCPError(null);
  };

  const confirmPinLocation = async () => {
    setSendingPin(true);
    let updatedProp;
    try {
      updatedProp = await updatePropertyDetails(propertyDetails.uuid, {
        userLongitude: parseFloat(propertyDetails.longitude),
        userLatitude: parseFloat(propertyDetails.latitude),
      });
      setSendingPin(false);
    } catch (error) {
      setSendingPin(false);
      cPErrorMessage(error?.message);
    } finally {
      if (updatedProp) {
        props.setActionRequired(updatedProp.data.actionRequired);
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerLeftRight}>
          <div className={styles.right}>
            <div className={styles.formContainer}>
              <div className={styles.formTop} onClick={toggle}>
                <Typography as="h3">Property Information Form</Typography>
                {showForm && <ArrowUp />}
                {!showForm && <ArrowDown />}
              </div>
              {showForm && (
                <PropertyForm
                  isReadOnly={readOnly}
                  formRef={formRef}
                  onNext={props.onNext}
                  onSubmit={onSubmitCallback}
                  onSave={onSaveCallback}
                  propertyId={props.propertyId}
                  propertyUuid={propertyDetails.uuid}
                  initialValues={propertyDetails}
                  propertyDetails={propertyDetails}
                />
              )}
            </div>
            <div className={styles.topMenu}>
              {propertyAnalyticsPath.map((pathObject, index) => (
                <Links
                  as="navLink"
                  path={`${url}${pathObject.path}`}
                  key={index}
                >
                  {pathObject.name}
                </Links>
              ))}
            </div>
            <div className={styles.rentalAndSalesContainer}>
              <Prompt
                message={(location) => {
                  const isJourneyOrSupport =
                    location.pathname.includes("journey") ||
                    location.pathname.includes("support");
                  if (
                    isJourneyOrSupport &&
                    !propertyDetails.propertyType &&
                    !!formRef.current
                  ) {
                    formRef.current.onMenuOpen();
                    return false;
                  }
                  return true;
                }}
              />
              <Switch>
                {propertyAnalyticsPath.map((pathObject, index) => (
                  <Route exact path={`${url}${pathObject.path}`} key={index}>
                    <pathObject.Component
                      propertyDetails={propertyDetails}
                      analytics={analyticsDataObjects[index]}
                      canViewPremiumData={canViewPremiumData}
                      openSponsorModal={openSponsorModal}
                    />
                    {viewButton(analyticsDataObjects[index]) && (
                      <>
                        {!loading && (
                          <Button
                            actionName={loading ? "" : pathObject.dataButton}
                            onClick={() => {
                              onRequestDataAnalytics(
                                propertyDetails.propertyType,
                                pathObject.analyticName
                              );
                            }}
                          />
                        )}
                        {loading && <SpinnerCircularFixed />}
                      </>
                    )}
                    {pathObject.analyticName === "compareRents" ? (
                      <>
                        <div className={styles.hDivider}></div>
                        <span className={styles.disclaimer}>
                          <Typography as="propertyInfoAnalyticsData">
                            Rental rates are based on the data available in the
                            local market and may not always be perfect. If your
                            property is in good condition, or has key rental
                            features, you may get a more rental income for your
                            property. Speak to a member of the team for expert
                            pricing and support.
                          </Typography>
                          <div
                            className={styles.contactButton}
                            onClick={openEmailModal}
                          >
                            <a>Get in contact</a>
                          </div>
                          {isEmailModalOpen && (
                            <ModalV2 onClose={closeEmailModal}>
                              <SendEmailModal
                                onClose={closeEmailModal}
                                user={userDetails}
                                property={propertyDetails}
                              />
                            </ModalV2>
                          )}
                        </span>
                      </>
                    ) : null}
                  </Route>
                ))}
                <Redirect to={`${url}${propertyAnalyticsPath[0].path}`} />
              </Switch>
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.map}>
              {width <= 768 && (
                <div className={styles.mapWarning}>
                  <br />
                  Please use a computer when trying to edit the map pin location
                </div>
              )}
              <Map
                onNewCoordinate={onNewCoordinate}
                readOnly={propertyDetails.CRMUniqueIdentifier ? true : false}
                latitude={propertyDetails.preferredCoordinates.latitude}
                longitude={propertyDetails.preferredCoordinates.longitude}
              />
              <div className={styles.mapGuide} onClick={openMapModal}>
                {propertyDetails.CRMUniqueIdentifier
                  ? "If the pin is not directly on the property please adjust it using your CRM software."
                  : "If the pin is not directly on the property you can adjust it by double clicking pin."}
                <Info />
              </div>
            </div>

            <div className={styles.mapGuideButton}>
              <div
                className={styles.cPError}
                style={{ display: CPV ? "flex" : "none" }}
              >
                Error: {cPError}
              </div>
              {confirmPin && (
                <div
                  className={
                    sendingPin
                      ? `${styles.confirmButton} ${styles.loading}`
                      : styles.confirmButton
                  }
                  onClick={confirmPinLocation}
                >
                  {sendingPin ? (
                    <SpinnerCircularFixed
                      color="#F2C94C"
                      secondaryColor="#ddd"
                      size="2rem"
                    />
                  ) : (
                    "Confirm Pin Location"
                  )}
                </div>
              )}
            </div>
            {isMapModalOpen && (
              <ModalV2 onClose={closeMapModal}>
                <MapGuideModal onClose={closeMapModal} />
              </ModalV2>
            )}
            {/* {!!guidelines && (
              <LicencesList
                loading={!guidelines}
                propertyDetails={propertyDetails}
                formRef={formRef}
                licences={guidelines}
                canViewPremiumData={true}
                openSponsorModal={openSponsorModal}
                onSelectSponsor={onSelectSponsor}
              />
            )} */}

            {width <= 768 && (
              <div className={styles.journeyButton}>
                <Button
                  actionName="Journey to Let & Management"
                  onClick={props.onNext}
                />
              </div>
            )}
          </div>
        </div>
        {width > 768 && (
          <div className={styles.journeyButton}>
            <Button
              actionName="Journey to Let & Management"
              onClick={props.onNext}
            />
          </div>
        )}
      </div>
    </>
  );
};

PropertyInfo.propTypes = {};

export default PropertyInfo;
