import { Details, LocationPin } from "images";
import { memo, useState } from "react";
import withStyles from "react-jss";
import { Image, WhiteButton } from "v2/components";
import Label from "v2/components/Form/Label/index";
import useModal from "Hooks/useModal";
import { formatter } from "yuno-formatting";
import { Styles } from "./Styles";
import { tooltipConstants } from "./constants";
import TooltipModal from "v2/components/ModalV2/TooltipModal/TooltipModal";
import { ReactComponent as NoUPRN } from "images/noUPRN.svg";
import { Button } from "../../../v2/components/Form";
import { updatePropertyDetails } from "Apis";
import { SpinnerCircularFixed } from "spinners-react";
import style from "./index.module.css";

const ViewDetails = ({ classes, handleClose, propertyDetails }) => {
  const [
    isTooltipModalOpen,
    openToolTipModal,
    closeToolTipModal,
    tooltipModalData,
  ] = useModal();
  const [noUPRNWarning, setNoUPRNWarning] = useState(
    propertyDetails.actionRequired &&
      propertyDetails.actionRequired.some((x) => x.value === "missing uprn")
  );
  const [sendingPin, setSendingPin] = useState(false);

  const confirmPinLocation = async () => {
    setSendingPin(true);
    let updatedProp;
    try {
      updatedProp = await updatePropertyDetails(propertyDetails.uuid, {
        userLongitude: parseFloat(propertyDetails.longitude),
        userLatitude: parseFloat(propertyDetails.latitude),
      });
      setSendingPin(false);
      if (
        updatedProp.data.actionRequired &&
        !updatedProp.data.actionRequired.some((x) => x.value === "missing uprn")
      )
        setNoUPRNWarning(false);
    } catch (e) {
      setSendingPin(false);
    }
  };

  const capitalize = (string) => {
    return typeof string === "string"
      ? string.charAt(0).toUpperCase() + string.slice(1)
      : string;
  };

  const DetailsItem = (props) => (
    <div className={style.tenancyItem}>
      <span className="detailLabel">{props.title}</span>
      <span className="detailValue">{capitalize(props.value)}</span>
    </div>
  );

  const DetailsSection = ({ title, image, valuesArray, map }) => (
    <>
      <div className={style.tenanciesContainer}>
        <div className={style.sectionTitle}>
          <div className="detailsHeading">
            <Image src={image} alt={title} />
            {title}
          </div>
        </div>
        {valuesArray.map((t, index) => (
          <>
            <div className={style.tenancyContainer}>
              {Object.entries(map).map(([k, v]) => (
                <DetailsItem title={k} value={t[v] ?? "-"} />
              ))}
            </div>
            {index !== valuesArray.length - 1 && (
              <div className={style.tenancyDivider}></div>
            )}
          </>
        ))}
      </div>
    </>
  );

  return (
    <div className={classes.detailsPopup}>
      {isTooltipModalOpen && (
        <TooltipModal
          onClose={closeToolTipModal}
          content={tooltipConstants[tooltipModalData.field]}
        />
      )}
      <div>
        <div className="detailsMain">
          <div className="detailsSection">
            <div className="detailsHeading">
              <Image src={LocationPin} alt="Location" />
              Location
            </div>
            <div className="closeButton">
              <WhiteButton
                as="click"
                className={classes.whiteButton}
                onClick={() => handleClose(false)}
              >
                Close
              </WhiteButton>
            </div>
          </div>
          <div className="detailsItemsWrap">
            <div className="detailsItems">
              <div className="detailsItem">
                <span className="detailLabel">
                  <Label
                    onInputTip={() => openToolTipModal({ field: "whatIsUPRN" })}
                    label="UPRN Number"
                  ></Label>
                </span>
                <span className="detailValue">{propertyDetails.UPRN}</span>
              </div>
              <div className="detailsItem">
                <span className="detailLabel">Address</span>
                <span className="detailValue">
                  <div className="addressSection">
                    {formatter.formatShortAddress(propertyDetails)}
                  </div>
                </span>
              </div>
              <div className="detailsItem">
                <span className="detailLabel">Council</span>
                <span className="detailValue">
                  <div className="addressSection">
                    {propertyDetails.councilName}
                  </div>
                </span>
              </div>
            </div>

            <div className="detailsItems">
              <div className="detailsItem">
                <span className="detailLabel">Status</span>
                <span className="detailValue">{propertyDetails.status}</span>
              </div>
              {propertyDetails.longitude && (
                <>
                  <div className="detailsItem">
                    <span className="detailLabel">Latitude</span>
                    <span className="detailValue">
                      {propertyDetails.latitude}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">Longitude</span>
                    <span className="detailValue">
                      {propertyDetails.longitude}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {noUPRNWarning && (
            <div className="noUPRN">
              <div className="leftSide">
                <NoUPRN />
                <span className="warningText">
                  We were unable to match this property to a UPRN, this could
                  affect the accuracy of the report.
                </span>
              </div>
              <div className="rightSide">
                {!sendingPin ? (
                  <Button
                    variant="verifyAddress"
                    actionName="Verify Address"
                    size="small"
                    onClick={confirmPinLocation}
                  />
                ) : (
                  <SpinnerCircularFixed
                    color="#F2C94C"
                    secondaryColor="#ddd"
                    size="2rem"
                  />
                )}
              </div>
            </div>
          )}

          <div className="topDetailsItems">
            {propertyDetails.parentUprn && (
              <div className="detailsItem">
                <span className="detailLabel">Parent UPRN Number</span>
                <span className="detailValue">
                  {propertyDetails.parentUprn}
                </span>
              </div>
            )}
            {propertyDetails.userLongitude && (
              <>
                <div className="detailsItem">
                  <span className="detailLabel">User Latitude</span>
                  <span className="detailValue">
                    {propertyDetails.userLatitude}
                  </span>
                </div>
                <div className="detailsItem">
                  <span className="detailLabel">User Longitude</span>
                  <span className="detailValue">
                    {propertyDetails.userLongitude}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <DetailsSection
          title="User Details"
          image={Details}
          valuesArray={[propertyDetails.createdByUser]}
          map={{
            "Created By": "fullName",
            "User Email": "email",
            "Phone Number": "phone",
          }}
        />
        <DetailsSection
          title="Details"
          image={Details}
          valuesArray={[propertyDetails]}
          map={{
            "Property Type": "propertyType",
            "Property Category": "propertyType",
            "Commercial Premises in Building?": "commercialPremises",
            Storeys: "numberOfStoreys",
            "Rooms/Units": "roomsOrUnits",
            "Construction Type": "buildingConstructionType",
          }}
        />
        <DetailsSection
          title="Tenancies"
          image={Details}
          valuesArray={propertyDetails.tenancies}
          map={{
            "Tenancy ID": "tenancyId",
            Status: "status",
            "CRM ID": "crmTenancyId",
            "Number of Tenants": "numberOfTenants",
            "Number of Households": "numberOfHouseholds",
            "Tenancy Type": "tenancyType",
            "Start Date": "tenancyStartDate",
            "End Date": "tenancyEndDate",
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(Styles)(memo(ViewDetails));
