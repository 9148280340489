import breakpoints from "theme/breakpoints";

export const Styles = ({ colors }) => ({
  modal: {
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px;",
    "& .modal > svg": {
      alignSelf: "flex-end",
      cursor: "pointer",
    },
  },
  crossIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
    position: "-webkit-sticky",
    position: "sticky",
    top: "15px",
    right: "15px",
  },

  detailsPopup: {
    width: "100%",
    paddingBottom: "3rem",
    zIndex: 700,
    height: "auto",
    position: "absolute",
    color: colors.labelColor,
    background: `${colors.pureWhite} !important`,
    boxShadow: "0px 7px 14px rgba(9, 30, 66, 0.25)",
    "& .detailDiv": {
      display: "flex",
    },

    [breakpoints.MOBILE_DEVICE]: {
      height: "auto",
      width: "100%",
      marginTop: "5rem",
      "& .lowerSection": {
        position: "relative",
        left: "-43px",
      },
      "& .detailsDiv": {
        display: "block",
        width: "100%",
        "& .detailsDivStyle": {
          [breakpoints.MOBILE_DEVICE]: {
            display: "flex",
            justifyContent: "space-between",
            padding: "0 2rem",
          },
        },
        "& .bottomSection": {
          width: "100%",
          display: "block",
          float: "left",
          marginTop: "24px",
          marginBottom: "24px",
          "& .bottomDiv": {
            display: "block",
            width: "50%",
            margin: "0",
            maxWidth: "inherit",
            float: "left",
          },
          "& .rightDiv": {
            margin: "0",
          },
        },
        "& .topSection": {
          display: "block",
          width: "50%",
          float: "left",
          boxSizing: "border-box",
          margin: 0,
        },
      },
      "& .detailDiv": {
        display: "flex",
        position: "relative",
      },
      "& .closeButton": {
        display: "none",
      },
    },
    "& .buttonContainer": {
      marginTop: "23px",
      marginLeft: "223px",
      marginBottom: "2rem",
      "& button": {
        [breakpoints.MOBILE_DEVICE]: {
          marginLeft: "16px",
        },
      },
    },
    "& .detailsMain": {
      marginBottom: "48px",
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
        marginBottom: "unset",
      },
      "& .detailsItemsWrap": {
        [breakpoints.MOBILE_DEVICE]: {
          display: "flex",
          justifyContent: "space-between",
          padding: "0 2rem",
        },
      },
    },
    "& .noUPRN": {
      borderLeft: "4px solid #F49342",
      marginRight: "220px",
      fontWeight: "600",
      height: "5rem",
      marginLeft: "223px",
      marginTop: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#F4934210",
      justifyContent: "space-between",
      padding: "0 1rem",
    },
    "& .actionsRequired": {
      borderLeft: "4px solid #F2C94C",
      marginRight: "220px",
      fontWeight: "600",
      height: "5rem",
      marginLeft: "223px",
      marginTop: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FCF1CDB2",
      justifyContent: "space-between",
      padding: "0 1rem",
    },
    "& .actionsRequiredCritical": {
      borderLeft: "4px solid #E2574C",
      marginRight: "220px",
      fontWeight: "600",
      height: "5rem",
      marginLeft: "223px",
      marginTop: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FBE6E4",
      justifyContent: "space-between",
      padding: "0 1rem",
    },
    "& .warningText": {
      marginLeft: "1rem",
    },
    "& .leftSide": {
      display: "flex",
      alignItems: "center",
    },
    "& .rightSide": {
      display: "flex",
      alignItems: "center",
    },

    "& .topDetailsItems": {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: "223px",
      marginRight: "220px",
      [breakpoints.MOBILE_DEVICE]: {
        flexDirection: "column",
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    "& .midDetailsItems": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft: "223px",
      marginRight: "220px",
      borderBottom: `1px solid ${colors.hamptonsNewDisabled}`,
      paddingBottom: "40px",
      [breakpoints.MOBILE_DEVICE]: {
        flexDirection: "column",
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    "& .detailsItems": {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "223px",
      marginRight: "220px",
      [breakpoints.MOBILE_DEVICE]: {
        flexDirection: "column",
        margin: "0",
      },
    },
    "& .bottomDetailsItems": {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "223px",
      [breakpoints.MOBILE_DEVICE]: {
        marginLeft: "16px",
        marginRight: "0px",
        flexDirection: "inherit",
        width: "100%",
      },
    },
    "& .lowerBottomDetailsItems": {
      display: "flex",
      justifyContent: "space-between",
      [breakpoints.MOBILE_DEVICE]: {
        marginLeft: "16px",
        marginRight: "0px",
        position: "absolute",
        bottom: "-31px",
        left: "0px",
        display: "flex",
        flexDirection: "column",
      },
    },
    "& .detailsItem": {
      display: "flex",
      flexDirection: "column",
      marginTop: "24px",
      flexBasis: "33.33%",
      maxWidth: "33.33%",
      [breakpoints.MOBILE_DEVICE]: {
        marginTop: "1rem",
        flexBasis: "unset",
        maxWidth: "unset",
      },
    },
    "& .detailLabel": {
      color: colors.labelColor,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.002em",
      marginBottom: "5px",
    },
    "& .detailValue": {
      color: colors.labelColor,
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "24px",
      "& .addressSection": {
        [breakpoints.MOBILE_DEVICE]: {
          wordBreak: "keep-all",
          whiteSpace: "pre",
        },
      },
      "& div": {
        wordBreak: "break-word",
      },
      "& span": {
        marginLeft: "2px",
      },
    },
    "& .detailsBottomSection": {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      marginLeft: "225px",
      [breakpoints.MOBILE_DEVICE]: {
        marginLeft: "19px",
      },
    },
    "& .detailsSection": {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "223px",
      marginRight: "220px",
      alignItems: "center",
      marginTop: "20px",
      [breakpoints.MOBILE_DEVICE]: {
        marginLeft: "19px",
      },
      " & .closeButton": {
        "& button": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: colors.labelColor,
        },
      },
    },
    "& .detailsHeading": {
      fontSize: "18px",
      fontWeight: 500,
      color: colors.primary,
      alignItems: "center",
      display: "flex",
      "& img": {
        marginRight: "14px",
      },
    },
  },
});
