import propTypes from "prop-types";

import styles from "./Typography.module.css";

const Typography = (props) => {
  switch (props.as) {
    case "h1":
      return (
        <h1 className={styles.h1} {...props.headingProps}>
          {props.children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={styles.h2} {...props.headingProps}>
          {props.children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={styles.h3} {...props.headingProps}>
          {props.children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={styles.h4} {...props.headingProps}>
          {props.children}
        </h4>
      );
    case "h5":
      return (
        <h5 className={`${props.className}`} {...props.headingProps}>
          {props.children}
        </h5>
      );
    case "h6":
      return (
        <h6 className={styles.h6} {...props.headingProps}>
          {props.children}
        </h6>
      );
    case "h6PropertyBox":
      return (
        <h6
          className={`${styles.h6} ${styles.PropertyBox}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6Dashboard":
      return (
        <h6
          className={`${styles.h6} ${styles.Dashboard}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6Documents":
      return (
        <h6
          className={`${styles.h6} ${styles.Documents}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6License":
      return (
        <h6
          className={`${styles.h6} ${styles.License}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6Compliant":
      return (
        <h6
          className={`${styles.h6} ${styles.Compliant}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6manualInsert":
      return (
        <h6
          className={`${styles.h6} ${styles.manualInsert}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "h6manualInsertResult":
      return (
        <h6
          className={`${styles.h6} ${styles.manualInsertResult}`}
          {...props.headingProps}
        >
          {props.children}
        </h6>
      );
    case "label":
      return (
        <label className={`${props.className}`} {...props.headingProps}>
          {props.children}
        </label>
      );
    case "subtitle":
      return (
        <p
          className={`${styles.subtitle} ${props.className}`}
          {...props.headingProps}
        >
          {props.children}
        </p>
      );
    case "link":
      return (
        <span className={styles.link} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "list":
      return (
        <span className={styles.list} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "Button":
      return (
        <span className={styles.Button} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "h6MapMarker":
      return (
        <span className={styles.MapMarker} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "uploadDocuments":
      return (
        <span className={styles.uploadDocuments} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentInfo":
      return (
        <span className={styles.documentInfo} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentInfoGrey":
      return (
        <span className={styles.documentInfoGrey} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentEditLink":
      return (
        <span className={styles.documentEditLink} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentTitle":
      return (
        <span className={styles.documentTitle} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "uploadText":
      return (
        <span className={styles.uploadText} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "licenseName":
      return (
        <span className={styles.licenseName} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "licensePrice":
      return (
        <span className={styles.licensePrice} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "licenseDescription":
      return (
        <span className={styles.licenseDescription} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentLabel":
      return (
        <span className={styles.documentLabel} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentLabelRef":
      return (
        <span className={styles.documentLabelRef} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentRef":
      return (
        <span className={styles.documentRef} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentName":
      return (
        <span className={styles.documentName} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "documentEditBtn":
      return (
        <span className={styles.documentEditBtn} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "heading-text":
      return (
        <span className={styles.headingText} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "strong":
      return <strong>{props.children}</strong>;

    case "UploadDocuments":
      return (
        <span className={styles.UploadDocuments} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "header":
      return (
        <span className={styles.header} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "upgradeBanner":
      return (
        <span className={styles.upgradeBanner} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "upgradeBannerSub":
      return (
        <span className={styles.upgradeBannerSub} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "upgradeBannerList":
      return (
        <span className={styles.upgradeBannerList} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "jobDetailsMainText":
      return (
        <span className={styles.jobDetailsMainText} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "jobDetailsSubText":
      return (
        <span className={styles.jobDetailsSubText} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "termsAndConditions":
      return (
        <span className={styles.termsAndConditions} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "disclaimer":
      return (
        <span className={styles.disclaimer} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "tabText":
      return (
        <span className={styles.tabText} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "noteTitle":
      return <p className={styles.noteTitle}>{props.children}</p>;
    case "noteText":
      return <p className={styles.noteText}>{props.children}</p>;
    case "alertBox":
      return (
        <span className={styles.alertBox} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "propertyInfoAnalytics":
      return (
        <span className={styles.propertyInfoAnalytics} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "propertyInfoAnalyticsData":
      return (
        <span
          className={styles.propertyInfoAnalyticsData}
          {...props.headingProps}
        >
          {props.children}
        </span>
      );
    case "pickSponsor":
      return (
        <span className={styles.pickSponsor} {...props.headingProps}>
          {props.children}
        </span>
      );
    case "pickSponsorBold":
      return (
        <span className={styles.pickSponsorBold} {...props.headingProps}>
          {props.children}
        </span>
      );
    default:
      return (
        <p
          className={`${styles.pTag} ${props.className}`}
          {...props.headingProps}
        >
          {props.children}
        </p>
      );
  }
};

Typography.propTypes = {
  children: propTypes.node.isRequired,
  as: propTypes.node,
  headingProps: propTypes.object,
  className: propTypes.string,
};

Typography.defaultProps = {
  as: "p",
  headingProps: {},
  children: "",
  className: "",
};

export default Typography;
