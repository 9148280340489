import propTypes from "prop-types";
import { MapContainer } from "react-leaflet";
import TileLayerWithHeader from "./TileLayerWithHeader";
import DraggableMarker from "./DraggableMarker";
import styles from "./Map.module.css";
import { getLoginInfo } from "utils";
import "./Map.css";

const { accessToken } = getLoginInfo();
const Map = (props) => {
  const center = [props.latitude, props.longitude];
  return (
    <MapContainer
      attributionControl={false}
      minZoom={8}
      maxZoom={20}
      center={center}
      zoom={18}
      scrollWheelZoom
      className={styles.map}
    >
      {props.TileLayer}
      <TileLayerWithHeader
        url={process.env.REACT_APP_API_URL}
        maxZoom={20}
        headers={{
          "x-access-token": accessToken,
          "Content-Type": "image/png",
          Accept: "image/png",
        }}
      />
      <DraggableMarker
        readOnly={props.readOnly}
        noUPRN={props.noUPRN}
        coordinates={center}
        onNewCoordinate={props.onNewCoordinate}
      />
    </MapContainer>
  );
};

Map.propTypes = {
  latitude: propTypes.string.isRequired,
  longitude: propTypes.string.isRequired,
  zoomLevel: propTypes.number,
};

Map.defaultProps = {
  zoomLevel: 13,
};

export default Map;
